@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

* {
	box-sizing: border-box;
	font-family: 'Lato', sans-serif;
}

body {
	margin: 0;
	padding: 0;
}

.week {
	display: flex;
}

.day {
	width: 80px;
}

.holiday {
	width: 120px;
}

.time {
	display: flex;
	justify-content: space-between;
	width: 100px;

}

.treatment {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.title {
	flex: .8
}
